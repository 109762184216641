
export default {
    name: 'NoData',
    props: {
        text: {
            type: String,
            default: '暂无数据'
        }
    },
    setup(){
        const reloadPage = () => {
            location.reload();
        }
        return {
            reloadPage
        }
    }
}
