<template>
    <div class="no-data">
        <img class="img" src="@/assets/no-data.svg" />
        <p>{{ text }}</p>
        <n-button class="btn" type="primary" shape="round" @click="reloadPage">刷新页面</n-button>
    </div>
</template>

<script lang="ts">
export default {
    name: 'NoData',
    props: {
        text: {
            type: String,
            default: '暂无数据'
        }
    },
    setup(){
        const reloadPage = () => {
            location.reload();
        }
        return {
            reloadPage
        }
    }
}
</script>

<style lang="scss" scoped>
// 空状态
.no-data{
    @extend .flex-column-center;
    width: 100%;
    margin: 20px 0;
    .img{
        width: 300px;
    }
    .btn{
        width: 120px;
    }
}
</style>